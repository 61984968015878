<template>
    <div>
        <TelegramSubscriptionBlock v-if="showTelegramSubscriptionBlock" @close="showTelegramSubscriptionBlock = false" @manualClose="showTelegramSubscriptionBlock = false; telegramNotificationsFlag=false"/>
        <div class="flex items-center mt-5 justify-between px-[17px]">
            <button @click="$router.push('/school-rules')" class="px-[9.5px] border-[#A1D9F7] text-[#FFF] rounded-[9px]  py-2 w-[83%] text-[13px] flex items-center align-middle justify-between">
                <div class="w-full text-[13px] relative top-[0.8px] text-start">PUSH-сповіщення у додатку</div>
            </button>
            <IphoneSwitch :isOnValue="pushNotificationsFlag" @change="processPushSwitch" />
        </div>
        <div class="flex items-center mt-5 justify-between px-[17px]">
            <button @click="$router.push('/school-rules')" class="px-[9.5px] border-[#A1D9F7] text-[#FFF] rounded-[9px]  py-2 w-[83%] text-[13px] flex items-center align-middle justify-between">
                <div class="w-full text-[13px] relative top-[0.8px] text-start">Сповіщення у Telegram</div>
            </button>
            <IphoneSwitch :isOnValue="telegramNotificationsFlag" @change="processTelegramSwitch" />
        </div>
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import { useMainStore } from '../../store/mainStore'
    import { checkPushNotificationState, deletePushNotificationToken, addPushTokenToStudent, checkBotSubscribe, deleteTelegramAlertsUser } from '../../services/apiRequests'
    import { base64UrlToUint8Array } from './encryption/helpers.js'
    import { initializeApp } from "firebase/app";
    import { getMessaging, getToken, onMessage } from "firebase/messaging";

    import IphoneSwitch from './IphoneSwitch.vue'
    import TelegramSubscriptionBlock from './TelegramSubscriptionBlock.vue'

    export default {
        name: 'NotificationsBlock',

        setup() {
            const pushNotificationsFlag = ref(false)
            const telegramNotificationsFlag = ref(false)
            const showTelegramSubscriptionBlock = ref(false)
            const permissionGranted = ref(false)
            const store = useMainStore()

            const getCurrentToken = async () => {
                const messaging = getMessaging();
                const currentToken = await getToken(messaging, { vapidKey: 'BJCBWIwIL5nEZ96V18emXQq5qBkjbJI8HIji5-nptsj-VY3aPmiSZ7n8LI4H5Wj9g7SKphnXU1rZX4L5wXUhGMo' })
                return currentToken
            } 

            const deletePushToken = async () => {
                const token = await getCurrentToken()
                console.log('token --------- ', token)
                if (token) {
                    const res = await deletePushNotificationToken(token)
                    console.log('res --------- ', res)
                }
            }

            const setPushMessages = async () => {
                const tokenPush = ref('')

                // const firebaseConfig = {
                //     apiKey: "AIzaSyADnyTft6NzTINwARsNciEfVzX9P_NBU2M",
                //     authDomain: "diem-front-student.firebaseapp.com",
                //     projectId: "diem-front-student",
                //     storageBucket: "diem-front-student.appspot.com",
                //     messagingSenderId: "630220681480",
                //     appId: "1:630220681480:web:4f348651a8194567677cbd",
                //     measurementId: "G-Z4WJ10RFRV"
                // };

                // const app = initializeApp(firebaseConfig);

                const messaging = getMessaging();

                const APPLICATION_KEYS = {
                    publicKey: 'BJCBWIwIL5nEZ96V18emXQq5qBkjbJI8HIji5-nptsj-VY3aPmiSZ7n8LI4H5Wj9g7SKphnXU1rZX4L5wXUhGMo',
                    privateKey: '-DybuTtj6NxdWPaghxpD44S1ZQ6CNEw6MGuQ2py8Z3c',
                };

                const publicApplicationKey = base64UrlToUint8Array(APPLICATION_KEYS.publicKey)
                if ('serviceWorker' in navigator && 'PushManager' in window) {
                    navigator.serviceWorker.ready
                        .then(registration => registration.pushManager.subscribe({ userVisibleOnly: true, applicationServerKey: publicApplicationKey }))
                        .then(subscription => {
                            permissionGranted.value = true; 
                        })
                        .catch(error => {
                            console.error('Ошибка при получении разрешения на Push-уведомления:', error);
                        });

                    getToken(messaging, { vapidKey: 'BJCBWIwIL5nEZ96V18emXQq5qBkjbJI8HIji5-nptsj-VY3aPmiSZ7n8LI4H5Wj9g7SKphnXU1rZX4L5wXUhGMo' }).then(async (currentToken) => {
                        if (currentToken) {
                            await addPushTokenToStudent(currentToken, store.student.student_id)
                            console.log("Token is:",currentToken);
                            // localStorage.setItem('push_subscription', '1')
                            // pushSubscription.value = true
                            // loadingSpinnerFlag.value = false
                            // alertType.value = ''
                        } else {
                            console.log('No registration token available. Request permission to generate one.');
                        }
                        }).catch((err) => {
                            console.log('An error occurred while retrieving token. ', err);
                        }
                    );
                } else {
                    console.warn('Push-уведомления не поддерживаются в вашем браузере.');
                }
            }

            const processPushSwitch = (value) => {
                pushNotificationsFlag.value = value

                if (value) {
                    setPushMessages()
                } else {
                    deletePushToken()
                }
            }

            const processTelegramSwitch = async (value) => {
                telegramNotificationsFlag.value = value
                if (value) {
                    showTelegramSubscriptionBlock.value = true
                } else {
                    await deleteTelegramAlertsUser()
                }
            }

            onMounted(async () => {
                const firebaseConfig = {
                    apiKey: "AIzaSyADnyTft6NzTINwARsNciEfVzX9P_NBU2M",
                    authDomain: "diem-front-student.firebaseapp.com",
                    projectId: "diem-front-student",
                    storageBucket: "diem-front-student.appspot.com",
                    messagingSenderId: "630220681480",
                    appId: "1:630220681480:web:4f348651a8194567677cbd",
                    measurementId: "G-Z4WJ10RFRV"
                }
                initializeApp(firebaseConfig)

                try {
                    const token = await getCurrentToken()
                    if (token) {
                        const resPush = await checkPushNotificationState(token)
                        console.log('resPush ------- ', resPush)
                        if (resPush.data.status == 'success') {
                            pushNotificationsFlag.value = true
                        } else {
                            pushNotificationsFlag.value = false
                        }
                    }
                } catch (e) {
                    pushNotificationsFlag.value = false
                }

                const resTelegram = await checkBotSubscribe(store.student.student_id)
                console.log('resTelegram ------- ', resTelegram)
                if (resTelegram.data.status == 'success') {
                    telegramNotificationsFlag.value = true
                } else {
                    telegramNotificationsFlag.value = false
                }
                // pushNotificationsFlag.value = 
                // telegramNotificationsFlag.value = 
            })
          
            return {
                pushNotificationsFlag,
                telegramNotificationsFlag,
                processPushSwitch,
                processTelegramSwitch,
                showTelegramSubscriptionBlock,
                telegramNotificationsFlag
            }
        },

        components: {
            IphoneSwitch,
            TelegramSubscriptionBlock
        }
    }
</script>

<style lang="scss" scoped>

</style>