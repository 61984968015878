<template>
    <div v-if="loading" class="bg-[#12214C] fixed w-full h-full">
        <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#FA1367] border-t-transparent absolute top-[50%] left-[50%] ml-[-20px]"></div>
    </div>
    <div v-else>
        <div @click="$router.push('/')" class="flex text-[#A1D9F7] items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#A1D9F7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2">
                Назад
            </div>
        </div>

        <div class="w-[100%] mt-[20px] px-[10px] overflow-y-auto">
            <div class="text-[#A1D9F7] text-center font-medium mb-[10px] text-[16px] ml-[20px]">МОЇ ПАРАМЕТРИ</div>
            <div class="h-px w-full bg-[#A1D9F7] rounded-full"></div>
            
            <div class="absolute left-[-56px]"> 
                <div 
                class="flex relative w-[220px] mt-[20px]"
                >
                    <div class="height-arrows-block h-[56%] w-[100px] flex text-[#A1D9F7] relative left-[52px] justify-center items-center flex-col">
                        <img src="../assets/up_arrow.svg" class="h-[50%]">
                        <div class="my-2"><span class="font-bold">{{ store.student.height }}</span> <span class="text-[14px]">см</span></div>
                        <img src="../assets/down_arrow.svg" class="h-[50%]">
                    </div>
                    <div class="h-[227px] relative w-[200px]">
                        <div class="text-[#A1D9F7] text-[14px] text-center">
                            <span class="font-bold">{{ getClothSize(store.student.breast_volume) }}</span> розмір
                        </div>
                        <div class="w-full flex justify-center h-full mb-[10px]">
                            <img src="../assets/student_params.svg" class="h-[85%]">
                        </div>
                        <div class="text-[#A1D9F7] text-[14px] absolute bottom-[5px] w-[100px] left-[52%] translate-x-[-50%] flex justify-between">
                            <div class="font-bold">{{ store.student.leading_foot == 'Права' ? store.student.leading_foot.slice(0,2) + '.' : 'Лв.' }}</div>
                            <div><span class="font-bold">{{ store.student.foot_size }}</span> р.</div>
                        </div>
                        <div class="text-[#A1D9F7] absolute right-0 top-[70px] text-[14px] breast-volume-block  text-center">
                            <span class="font-bold">{{ store.student.breast_volume }}</span> см
                        </div>
                        <div class="text-[#A1D9F7] text-[14px]  absolute bottom-[-20px] w-full text-center">
                            <span class="font-bold">{{ store.student.weight }}</span> вага (кг)
                        </div>
                    </div>
                </div>
            </div>

            <div class="absolute top-[110px] right-0 w-[200px] text-[#A1D9F7] text-[10px]">
                <div class="mb-[20px]">
                    · для отримання динаміки, вносіть на регулярній основі дані про зріст та вагу
                </div>
                <div>
                    · актуальні дані по розміру ноги, об’єму грудей та зросту, дають нам можливість оперативно робити закупку спортиіної форми для наших спортсменів  
                </div>
            </div>
        </div>

        <div class="charts-block relative flex items-center mt-[290px]">
            <div class="w-full">
                <div class="flex flex-col justify-between items-center">
                    <div class="relative flex flex-col w-full mb-[10px]">
                        <div class="text-[#A1D9F7] text-center text-[14px]">зріст</div>
                        <line-chart class="mx-auto" :color="'#91CBE5'" :data="store.studentsHeights" :width="335" :height="70"></line-chart>
                    </div>
                    <div class="relative flex flex-col w-full">
                        <div class="text-[#A1D9F7] text-center text-[14px]">вага</div>
                        <line-chart class="mx-auto" :color="'#F8FF82'" :data="store.studentsWeights" :width="335" :height="70"></line-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, watch, onMounted } from 'vue'
    import { useMainStore } from '../store/mainStore'
    import LineChart from '../components/StudentDataBlock/LineChart.vue'
    
    export default {
        name: 'StatsPage',


        setup() {
            const store = useMainStore()
            const loading = ref(true)

            onMounted(async () => {   
                await store.getStudentBodyParameters()
                loading.value = false
            })

            const getWeekDay = (dateString) => {
                const daysOfWeek = ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
                const date = new Date(dateString)
                const dayOfWeek = daysOfWeek[date.getDay()]
                return dayOfWeek
            }

            const getClothSize = (chestCircumference) => {
                let size = ''
                
                if (chestCircumference < 94) {
                    size = 'XS'
                } else if (chestCircumference < 98) {
                    size = 'S'
                } else if (chestCircumference < 102) {
                    size = 'M'
                } else if (chestCircumference < 106) {
                    size = 'L'
                } else if (chestCircumference < 110) {
                    size = 'XL'
                } else if (chestCircumference < 114) {
                    size = 'XXL'
                } else {
                    size = 'XXXL'
                }

                return size
            }

            return {
                store,
                getWeekDay,
                getClothSize,
                loading
            }
        },

        components: {
            LineChart
        }
    }
</script>