<template>
    <div class="container h-[58px]">
        <div class="w-[60%] flex justify-between mt-[2px]">
            <div class="data-field">
                <div class="data-text-size">
                    група
                </div>
                <div class="field-value">
                    {{ store.group?.name ?? '--' }}
                </div>
            </div>
            <div class="data-field">
                <div class="data-text-size">
                    вік
                </div>
                <div class="field-value">
                    {{ store.student.age ?? '--' }}
                </div>
            </div>
            <div class="data-field">
                <div class="data-text-size">
                    амплуа
                </div>
                <div class="field-value">
                    {{ store.student.amplua.length > 0 ? store.student.amplua : '--' }}
                </div>
            </div>
        </div>
        <div class="logos-field">
            <div class="logo-container">
                <img src="../../assets/minaj_icon.svg" class="fc-logo-container">
            </div>
            <div class="divider"></div>
            <div class="logo-container">
                <img src="../../assets/ukraine_flag.svg" class="country-flag-container">
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, onBeforeMount, watch } from 'vue'
    import { useMainStore } from '../../store/mainStore'

    export default {
        name: 'StudentBasicData',

        setup() {
            const store = useMainStore()

            return {
                store
            }
        }
    }
</script>

<style lang="scss" scoped>


    .container {
        @apply flex justify-between w-[582px];
    }

    .data-field {
        @apply flex flex-col text-center text-white justify-center px-[19px]; 
    }

    .logos-field {
        @apply flex w-max items-center text-center text-white justify-between mr-[20px] h-max;
    }

    .data-text-size {
        @apply text-[14px];
    }

    .field-value {
        @apply text-[28px] max-w-[10ch] relative top-[-7px] truncate font-bold;
    }

    .logo-container {
        @apply w-[55px] flex justify-between;
    }

    .divider {
        @apply w-px bg-white h-[40px] ml-[15px];
    }

    .fc-logo-container {
        @apply w-[55px] relative left-[10px];
    }

    @media (min-width: 1800px) and (max-width: 8000px) {
        .logos-field {
            @apply flex w-max items-center text-center text-white justify-between mr-[20px] h-max;
        }
        .country-flag-container {
            @apply w-[38px] ml-[23px];
        }
    }

    @media (min-width: 0px) and (max-width: 1800px) {
        .logos-field {
            @apply flex w-max items-center text-center text-white justify-between mr-[60px] mt-[0] h-max;
        }
        .country-flag-container {
            @apply w-[38px] ml-[23px];
        }
    }

</style>