<template>
    <div @click="$emit('close')" style="background-color: rgba(20, 37, 82, .25)" class="backdrop-blur-[2px] fixed w-full h-full z-20"></div>
    <div class="bg-[#12214C] px-4 py-3 border-[1px] absolute top-[50%] left-[50%] translate-x-[-50%] z-30 translate-y-[-50%] w-[350px] rounded-[10px]">
        <div>
            <div class="text-[12px] text-center font-medium text-[#91CBE5] mb-[10px] pt-[10px]">ОПЛАТА ВНЕСКУ ГРАВЦЯ</div>
            <div class="text-white font-bold w-full px-[10px] text-[24px] flex justify-between">
                <div>
                    {{ store.student.name + ' ' + store.student.surname }}
                </div>
                <div>
                    {{ store.group.name }}
                </div>
            </div>
            <div class="mt-[0px]">
                <div class="text-white text-[36px] font-bold text-center">{{ store.studentBalance < 0 ? (store.studentBalance * -1).toFixed(2) : 0.00 }}</div>
                <div class="text-white text-center text-[10px]">
                    (за попередній місяць)
                </div>
                <button @click="generateDebtTx" :disabled="+store.studentBalance >= 0" class="bg-[#41BF74] disabled:opacity-70 text-white font-semibold w-full py-1.5 mt-[30px] rounded-[10px]">
                    Провести оплату
                </button>
            </div>
        </div>
        <div>
            <div class="bg-[#91CBE5] text-center text-[#12214C] font-semibold w-full py-1.5 mt-[30px] rounded-[10px]">
                Внести іншу суму
            </div>
            <div>
                <input v-model="summ" type="text" class="outline-none border-none text-[#91CBE5] placeholder:text-[#91CBE5] text-[36px] w-full text-center placeholder:opacity-60 bg-transparent font-bold mt-[10px] mb-[10px]" placeholder="0.00">
            </div>
            <div class="text-[#91CBE5] text-center text-[14px]">
                (введіть суму, яку необхідно внести)
            </div>
            <button @click="generateCustomTx" class="bg-[#91CBE5] text-[#12214C] mb-[10px] font-semibold w-full py-1.5 mt-[30px] rounded-[10px]">
                Сплатити    
            </button>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import { useMainStore } from '../../store/mainStore'
    import { createWayforpayTransaction } from '../../services/apiRequests'

    export default {
        name: 'AddPaymentPopup',

        setup() {
            const store = useMainStore()
            const summ = ref(null)

            const generateCustomTx = async () => {
                const res = await createWayforpayTransaction(
                    summ.value,
                    store.student.student_id,
                    store.student.name,
                    store.student.surname,
                    store.student.email,
                    store.student.phone,
                )
                console.log(res.data)
                window.open(res.data.payment.url, '_parent')
            }

            const generateDebtTx = async () => {
                if (store.studentBalance >= 0) return
                const res = await createWayforpayTransaction(
                    store.studentBalance * -1,
                    store.student.student_id,
                    store.student.name,
                    store.student.surname,
                    store.student.email,
                    store.student.phone,
                )
                console.log(res)
                window.open(res.data.payment.url, '_parent')
            }

            return {
                store,
                summ,
                generateCustomTx,
                generateDebtTx
            }
        }
    }
</script>
