<template>
    <div class="messages-block border-blue-400 h-[308px]" :class="{'mt-[-150px]' : store.group.payment_status == 'free'}">
        <div class="text-[#A1D9F7] font-bold text-[15px] text-start">
            ПОВІДОМЛЕННЯ
        </div>
        <div class="message-box relative mt-[5px] overflow-auto pr-[8px] border-blue-400" :class="{'h-[270px]' : store.group.payment_status == 'paid', 'h-[400px]' : store.group.payment_status == 'free'}">
            <div v-for="message in store.studentMessages" class="w-full flex h-[58px] bg-[#A1D9F7] bg-opacity-5 rounded-[8px] border-[1px] border-[#4E5568] mb-2">
                <div v-if="message.sender_type == 'system'" class="h-full rounded-l-[8px] bg-[#F10265] w-[8px]"></div>
                <div v-if="message.sender_type == 'trainer'" class="h-full rounded-l-[8px] bg-green-400 w-[8px]"></div>
                <div v-if="message.sender_type == 'admin'" class="h-full rounded-l-[8px] bg-[#ABD3EC] w-[8px]"></div>
                <div class="w-[80%] flex px-5 text-white align-middle items-center justify-center">
                    <div class="text-sm opacity-50 w-[20%]">{{ message.date.split('T')[0].split('-').reverse().join('.') }}</div>
                    <div class="text-[14px] w-[80%] text-[#A1D9F7]">{{ message.text }}</div>
                </div>
                <div class="flex justify-center items-center w-[18.5%]">
                    <div v-if="message.sender_type == 'system'" class="rounded-2xl bg-[#F10265] w-[80.35px] text-[12px] mr-[12px] text-center text-white">
                        система
                    </div>
                    <div v-if="message.sender_type == 'trainer'" class="rounded-2xl bg-green-400 w-[80.35px] text-[12px] mr-[12px] text-center text-white">
                        тренер
                    </div>
                    <div v-if="message.sender_type == 'admin'" class="rounded-2xl bg-[#ABD3EC] w-[80.35px] text-[12px] mr-[12px] text-center text-white">
                        адмін
                    </div>
                </div>
            </div>
            <div v-for="i in (4 - store.studentMessages.length > 0 ? 4 - store.studentMessages.length : 0)" class="w-full flex h-[58px] rounded-[8px] bg-[#A1D9F7] bg-opacity-5 border-[1px] border-[#A5A3A3] border-opacity-20 mb-2">
                <div class="h-full rounded-l-md bg-gray-400 w-[8px]"></div>
                <div class="w-full relative h-full truncate text-[14px] text-[#A1D9F7] px-[10px] py-[5px]">
                    <div class="absolute top-[50%] opacity-60 left-[50%] translate-x-[-50%] translate-y-[-50%]">Відсутні повідомлень</div>    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { useMainStore } from '../../store/mainStore'
    import { ref, onMounted } from 'vue'

    export default {
        name: 'StudentMessagesBlock',

        props: {
            width: {
                type: String,
                default: '605px'
            },
            
        },

        setup() {
            const store = useMainStore()
    
            return {
                store
            }
        }
    }
</script>

<style lang="scss" scoped>
    .messages-block {
        width: v-bind(width);
    }
</style>