<template>
    <div class="px-2">
        <div class="w-full flex justify-center">
            <img src="../../assets/robot_icon.svg" class="w-9">
        </div>
        <div class="text-center bg-[#A1D9F7] bg-opacity-25 mt-4 py-2 rounded-lg font-bold text-[15px] text-[#A1D9F7]">
            Перейдіть до бота та натисніть кнопку "Почати"
        </div>
        <div @click="linkToBot" class="bg-[#A1D9F7] flex justify-center items-center mt-3 px-3 w-full h-[40px] rounded-md">
            <div class="w-4 mx-2"></div>
            <div class="font-bold text-[16px] text-[#000] text-center cursor-pointer">Лінк на бота</div>
            <div class="flex items-center"><img src="../../assets/link_icon.svg" class="w-5 mx-2 mt-1"></div>
        </div>
        <div v-if="clickedbotLink">
            <div class="text-center bg-[#A1D9F7] bg-opacity-25 mt-3 py-2 rounded-lg font-bold text-[15px] text-[#A1D9F7]">
                Тепер клікніть "Перевірити"
            </div>
            <div @click="checkBot" class="bg-[#41BF74] mt-3 flex justify-center items-center w-full h-[40px] rounded-md">
                <div class="font-bold text-[16px] text-[#FFF] cursor-pointer">Перевірити</div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import { checkBotSubscribe } from '../../services/apiRequests'
    import { useMainStore } from '../../store/mainStore'

    export default {
        name: 'BotAlerts',

        setup(props, { emit }) {
            const store = useMainStore()

            const clickedbotLink = ref(false)

            const linkToBot = () => {
                window.open(process.env.VUE_APP_BOT_ALERTS_URL + `?start=${store.student.student_id}`, '_blank')
                clickedbotLink.value = true
            }

            const checkBot = async () => {
                const res = await checkBotSubscribe(store.student.student_id)
                if (res.data.status == 'success') {
                    store.isBotSubscribed = true
                    emit('close')
                }
            }


            return {
                clickedbotLink,
                linkToBot,
                checkBot,
                store
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>