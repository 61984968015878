<template>
    <div v-if="loading">
        <Header 
            class="fixed top-[0] left-[50%] translate-x-[-50%]"
            :class="{
                'ml-[122px] w-[100%] px-[200px]' : screenWidth > 1300,
                'ml-[30px] w-[100%] px-[100px]' : screenWidth < 1300,
            }"     
        />
        <!-- <div class="text-white font-semibold text-[22px] mt-[10px] relative top-[4px] text-start ml-[56px]">Моя команда</div> -->
        <div class="flex mt-[33px]">
            <TeamField />
            <GroupList 
                class="mt-[25px] relative"
                :class="{
                    'left-[-190px]' : screenWidth > 1500,
                    'left-[-100px]' : screenWidth < 1500,
                }"
            />
        </div>
    </div>
    <div v-else>
        <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#FA1367] border-t-transparent absolute top-[50%] left-[50%] ml-[30px]"></div>
    </div>
</template>

<script>
    import { ref, onBeforeMount } from 'vue'
    import { useMainStore } from '../store/mainStore'


    import TeamField from '../components/TeamBlock/TeamField.vue'
    import GroupList from '../components/TeamBlock/GroupList.vue'
    import Header from '../components/Header.vue'

    export default {
        name: 'TeamPage',

        setup() {
            const loading = ref(false)
            const store = useMainStore()
            const screenWidth = ref(window.innerWidth)
            onBeforeMount(async () => {
                console.log('before mount')
                const studentRes = await store.getStudentData()
                console.log(studentRes)
                if (!studentRes) {
                    localStorage.removeItem('access_token')
                    localStorage.removeItem('refresh_token')
                    router.go()
                    return
                }
                await store.getGroupData()
                await store.getTrainerData()
                await store.getStudentIncomes()
                await store.getBalanceState()
                await store.getTelegramBotSubscribed()
                console.log('store.student.group_id ----- ', store.student.group_id.length > 0)
                if (store.student.group_id.length > 0) {
                    await store.getPlanedTrainings()
                    await store.getAllGroupStudents()
                }
                await store.getStudentMessages()
                await store.getStudentBodyParameters()
                await store.getStudentCompletedTrainings()
                loading.value = true

                window.addEventListener('resize', () => {
                    screenWidth.value = window.innerWidth
                    console.log(screenWidth.value)
                });
            })

            return {
                store,
                loading,
                screenWidth
            }
        },

        components: {
            TeamField,
            GroupList,
            Header
        }
    }
</script>
