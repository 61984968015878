import { createRouter, createWebHistory } from 'vue-router'
import MainLayout from '../layouts/MainLayout.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: MainLayout,
    meta: {
      component: 'MainPage'
    }
  },

  {
    path: '/finance',
    name: 'finance',
    component: MainLayout,
    meta: {
      component: 'FinancePage'
    }
  },

  {
    path: '/calendar',
    name: 'calendar',
    component: MainLayout,
    meta: {
      component: 'CalendarPage'
    }
  },

  {
    path: '/group',
    name: 'group',
    component: MainLayout,
    meta: {
      component: 'GroupPage'
    }
  },

  {
    path: '/settings',
    name: 'settings',
    component: MainLayout,
    meta: {
      component: 'SettingsPage'
    }
  },

  {
    path: '/team',
    name: 'team',
    component: MainLayout,
    meta: {
      component: 'TeamPage'
    }
  },

  {
    path: '/agreement-personal-data',
    name: 'agreement-personal-data',
    component: MainLayout,
    meta: {
      component: 'AgreementPersonalDataView'
    }
  },

  {
    path: '/school-rules',
    name: 'school-rules',
    component: MainLayout,
    meta: {
      component: 'SchoolRulesView'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
