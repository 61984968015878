<template>
    <div class="container-adaptive-text">
        <div class="subcontainer">
            <div class="flex w-full justify-between name-number-block items-end">
                <div class="flex items-end w-full justify-between">
                    <div class="name font-bold text-start leading-none relative top-[2px]">{{ name }}</div>
                    <div v-if="number" class="number font-bold text-end leading-none mt-[20px] relative top-[7px]"><span class="text-[48px]">#</span>{{ number }}</div>
                    <div v-else class="empty-block"></div>
                </div>
            </div>
            <div :class="
                {
                    'marquee-container' : surname.length >= 1,
                }
            ">
                <div class="surname font-black" 
                    :class="{
                        'marquee' : surname.length >= 1,
                    }"
                >
                    <span v-if="surname.length >= 1" v-for="z in 100">{{ surname.toUpperCase() + '&nbsp;&nbsp;' }}</span>
                    <span v-else>{{ surname.toUpperCase() }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, onBeforeMount, watch } from 'vue'

    export default {
        name: 'AdaptiveTextBlockNew',

        props: {
            name: {
                type: String,
                required: true
            },
            surname: {
                type: String,
                required: true
            },
            number: {
                type: String,
                required: true
            }
        },

        setup(props, { emit }) {
        }
    }

</script>

<style lang="scss" scoped>
    @font-face {
        font-family: 'Avenir Bold';
        src: url('../../assets/Avenir-bold.ttf');
    }

    @font-face {
        font-family: 'Avenir Regular';
        src: url('../../assets/Avenir-regular.ttf');
    }

    @font-face {
        font-family: 'Avenir LST Roman';
        src: url('../../assets/AvenirLTStd-Roman.otf');
    }

    @font-face {
        font-family: 'Fixel Regular';
        src: url('../../assets/FixelAll/FixelDisplay/FixelDisplay-Regular.ttf');
    }

    @font-face {
        font-family: 'Avenir LTS Medium';
        src: url('../../assets/AvenirLTStd-Medium.otf');
    }

    @font-face {
        font-family: 'Fixel SemiBold';
        src: url('../../assets/FixelAll/FixelDisplay/FixelDisplay-SemiBold.ttf');
    }

    .name {
        font-family: 'Avenir LST Roman';
        width: max-content;
        font-size: 50px;
    }

    .empty-block {
        @apply h-[5.5vw];
    }

    .marquee-container {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 125px;
    }

    .marquee {
        position: absolute;
        white-space: nowrap;
        height: max-content;
        animation: marquee 660s linear infinite;
    }


    .marquee > * {
        animation-delay: 25s;
    }


    @keyframes marquee {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(-100%);
        }
    }

    /* Стиль для Мака */
    @media screen and (-webkit-min-device-pixel-ratio: 2) {
        .surname {
            margin-top: -2px;
            line-height: 80%;
            font-size: 165px;
            font-weight: 400;
            width: max-content;
            color: white;
            font-family: 'Fixel SemiBold';
        }
    }

    /* Стиль для Линукса */
    @media screen and (not (-webkit-min-device-pixel-ratio: 2)) {
        .surname {
            margin-top: 2px;
            line-height: 100%;
            font-size: 155px;
            font-weight: 900;
            width: max-content;
            color: white;
            font-family: 'Avenir LST Roman';
        }
    }

    /* Стиль для Виндовс */
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .surname {
            margin: 0;
            line-height: 80%;
            font-size: 155px;
            font-weight: 600;
            width: max-content;
            color: white;
        }
    }

    @media (min-width: 0px) and (max-width: 1800px) {
        .name-number-block {
            margin-bottom: 33px;
        }

        .number {
            font-family: 'Avenir LST Roman';
            font-size: 90px;
            text-align: left;
            margin-right: 30px;
        }

        .subcontainer {
            @apply w-[100%] text-white;
        }
        
        .container-adaptive-text { 
            @apply flex justify-start;
        }

    }

    @media (min-width: 1800px) and (max-width: 8000px){
        .name-number-block {
            margin-bottom: 23px;
        }

        .container-adaptive-text { 
            @apply flex justify-center;
        }

        .name {
            position: relative;
            left: 27px;
        }

        .subcontainer {
            @apply w-full text-white;
        }

        .number {
            font-family: 'Avenir LST Roman';
            font-size: 95px;
            text-align: left;
            margin-right: 70px;
        }
    }

</style>
